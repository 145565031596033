<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">设备仿真</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model ref="ruleForm" :model="form" layout="inline" labelAlign="left">
			<a-form-model-item has-feedback label="设备编号">
				<a-input v-model="form.device_number" />
			</a-form-model-item>
			<a-form-model-item>
				<a-button type="primary" html-type="submit" @click="warn">
					模拟警报
				</a-button>
			</a-form-model-item>
		</a-form-model>
	</div>

</template>

<script>
import {
	domain
} from '@/siteInfo.js'
import {
	get
} from 'js-cookie'
import {
	type
} from 'os'

export default {
	data() {
		return {
			form: {},
		}
	},
	methods: {
		warn() {
			let class_type = 1
			this.$post(domain + '/admin/v1/warn/simulation', {
				'device_number': this.form.device_number
			})
				.then(res => {
					let {
						code,
						msg,
						data
					} = res
					if (code == 0) {
						this.$message.success(msg, 1.5)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => { })
		},
	},
}
</script>

<style lang="less" scoped>
.img-box {
	overflow: hidden;
	display: inline-block;

	.img-icon {
		transform: translateX(-100px);
		filter: drop-shadow(blue 100px 0)
	}
}

.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
